import React from 'react';
import classnames from 'classnames';
import { bool, object } from 'prop-types';
import { SearchBar } from '@fiverr-private/search_bar';
import { abTests, localeSettings } from '../../PropTypes';

import './style.scss';

const SearchAnimated = ({ show = false, rollouts = {} }, { locale, abTests, currency = {} }) => {
    const classes = classnames(
        'fiverr-header-search-animated',
        { 'fiverr-header-search-animated-show': show },
        'fiverr-header-search-animated-long'
    );

    return (
        <div className={classes}>
            <SearchBar
                source="top-bar"
                locale={locale}
                abTests={abTests}
                rollouts={rollouts}
                currency={currency}
                theme="dark"
                dimBackgroundEnabled={true}
            />
        </div>
    );
};

SearchAnimated.propTypes = {
    show: bool,
    rollouts: object,
};

SearchAnimated.contextTypes = {
    locale: localeSettings.locale,
    abTests,
    currency: localeSettings.currency,
};

export default SearchAnimated;
